.recharts-wrapper,
.recharts-surface,
.recharts-legend-wrapper {
  @media print {
    width: calc(100%) !important;
  }
}

.noprint {
  display: block;
  @media print {
    display: none;
  }
}

.print {
  display: none;
  @media print {
    display: block;
  }
}

.recharts-legend-item > svg {
  width: auto !important;
  display: inline-block !important;
  right: inherit !important; /* align to left */
}

body {
  @media print {
    width: 1200px !important;
    padding: 10px;
  }

  .topoPrint {
    display: none;

    @media print {
      background-color: rgb(255, 255, 255);
      padding: 20px 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .footerPrint {
    display: none;

    @media print {
      background-color: rgb(255, 255, 255);
      padding: 25px;
      display: block;
      align-items: center;
      justify-content: space-between;
      page-break-before: auto;
    }
  }
}

@media print {
  .page-break {
    page-break-before: always;
  }

  .page-break-avoid {
    page-break-before: avoid;
  }

  .page-break-after {
    page-break-after: always;
  }

  .page-break-inside {
    break-inside: avoid-page;
  }
}

.container1 {
  width: 100%;
}

.container-top {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: stretch;
  margin-bottom: 10px;

  .card {
    width: 200px;

    margin-right: 10px;
  }
}

.container2 {
  page-break-inside: avoid;
  width: 100%;
  display: flex;
  
  .alocacao {
    width: 50%;
  }

  .liquidez {
    width: 50%;
  }
}

