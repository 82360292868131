.recharts-wrapper,
.recharts-surface,
.recharts-legend-wrapper {
  @media print {
    width: calc(100%) !important;
  }
}

.recharts-legend-item > svg {
  width: auto !important;
  display: inline-block !important;
  right: inherit !important; /* align to left */
}

body {
  @media print {
    width: 1200px !important;
    padding: 10px;
    scale: 0.98;
  }

  .print {
    display: none;
    @media print {
      display: block;
    }
  }

  .notprint {
    display: block;
    @media print {
      display: none;
    }
  }

  .footerPrint {
    display: none;

    @media print {
      background-color: rgb(255, 255, 255);
      padding: 25px;
      display: block;
      align-items: center;
      justify-content: space-between;
      page-break-before: auto;
    }
  }
}

// @media all {
//   .page-break {
//     display: none;
//   }
// }

@media print {
  .page-break {
    page-break-before: always;
  }

  .page-break-avoid {
    page-break-before: avoid;
  }

  .page-break-dois {
    page-break-inside: avoid;
    visibility: visible;
    width: 100px;
    height: 10px;
    background-color: transparent;
  }
}
